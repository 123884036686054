<template>
  <onboarding-layout back-route-name="OnboardingPetBirthdate">
    <h1 class="h2 mb-2">
      Wat is je eigen naam?
    </h1>

    <div class="name-container">
      <p class="color-text mb-2">
        Je gegevens worden gebruikt om een account voor je aan te maken.
      </p>

      <form action="" method="POST" @submit.prevent="onSubmit">
        <Errors :errors="errors" />

        <div class="mb-2">
          <label class="visually-hidden" for="firstName">
            Vul hier je voornaam in
          </label>
          <input
            class="form-control form-control--center-placeholder"
            type="text"
            name="firstName"
            id="firstName"
            required
            v-model="formFirstName"
            placeholder="Vul hier je voornaam in"
          >
        </div>

        <div class="mb-2">
          <label class="visually-hidden" for="lastName">
            Vul hier je achternaam in
          </label>
          <input
            class="form-control form-control--center-placeholder"
            type="text"
            name="lastName"
            id="lastName"
            required
            v-model="formLastName"
            placeholder="Vul hier je achternaam in"
          >
        </div>

        <Button classes="btn--full-width" button-type="submit">
          Naar volgende stap
        </Button>
      </form>
    </div>
  </onboarding-layout>
</template>

<script>
import OnboardingLayout from './OnboardingLayout.vue';
import Errors from '../../components/Errors.vue';
import Button from '../../components/Button.vue';

export default {
  components: {
    Button,
    Errors,
    OnboardingLayout,
  },

  data() {
    return {
      errors: [],
      formFirstName: this.$store.state.onboarding.firstName,
      formLastName: this.$store.state.onboarding.lastName,
    };
  },

  created() {
    this.$store.commit('resetLayout', {
      showDog: this.petType === 'dog',
      showCat: this.petType === 'cat',
      showCta: false,
      progress: 66.666,
    });
  },

  mounted() {
    this.$gtm.trackEvent({
      event: 'onboarding',
      category: 'Onboarding',
      action: 'step',
      label: '4-user-name',
    });
  },

  methods: {
    validate() {
      this.errors = [];

      if (!this.formFirstName) {
        this.errors.push('Geef een voornaam op');
      }

      if (!this.formLastName) {
        this.errors.push('Geef een achternaam op');
      }

      return this.errors.length === 0;
    },

    onSubmit() {
      if (!this.validate()) {
        return;
      }

      this.$store.commit('setFirstName', this.formFirstName);
      this.$store.commit('setLastName', this.formLastName);

      this.$router.push({ name: 'OnboardingAvatar' });
    },
  },

  computed: {
    petType() {
      return this.$store.state.onboarding.petType;
    },
  },
};
</script>

<style>
.name-container {
  max-width: 25rem;
  margin-left: auto;
  margin-right: auto;
}
</style>
